<template>
 
    <v-card tile class="pa-3">
      <v-card-title
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title>Seite wird verlassen!</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>

      <v-card-text class="text--primary py-5 px-3">
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="auto" v-if="$route.meta.request === 'articles' || $route.meta.request === 'manufacturers' || $route.meta.request === 'orders/ordered'"
              > <span>Möchtest du diese Seite wirklich verlassen? Bereits getätigte Änderungen gehen verloren!</span> 
            </v-col>
            <v-col cols="auto" v-if="$route.meta.request === 'appointments/table'"
              >
              <v-row dense align="center">
                <v-col cols="12">
                <span>Möchtest du diese Seite wirklich verlassen?</span>
                </v-col>
                <v-col cols="12">
                <span>Bereits getätigte Änderungen, neu hinzugefügte Artikel, Accessoires oder Notizen werden nicht übernommen!</span>
                </v-col>
              </v-row>      
            </v-col>
            <v-col cols="auto" v-if="$route.meta.request === 'settings/masterdata'"
              > <span>Möchtest du diese Seite wirklich verlassen? Noch nicht importierte Artikel gehen verloren!</span> 
            </v-col>            
          </v-row>
        </v-col>
        
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            
            <v-col cols="auto">
              <v-btn
                outlined
                color="red lighten-1"
                @click="cancel"
              >
                <span>Nein</span>
              </v-btn>
            </v-col><v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn color="green lighten-1" @click="agree">
                <v-icon left class="grey--text text--lighten-4"
                  >mdi-check
                </v-icon>
                <span class="grey--text text--lighten-4"
                  >Ja</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  
</template>

<script>
export default {
  name: "dlg-frame", 
  data: () => ({}),
  inject: ["agree", "cancel"],
  methods: {}
};
</script>