<template>
  <v-dialog v-model="SelectArticle" width="700px" overlay-opacity="0.75" scrollable @click:outside="clearOnClickOutside">
    <v-card tile class="pa-3">
      <v-card-title class="px-1">
        <v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title>Artikel auswählen</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>
      <v-divider inset></v-divider>
      <v-card-text class="text--primary px-2 subtitle-2">
        <v-col cols="12" class="px-0">
          <v-row dense align="center">
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="font-weight-bold">Artikelnummer(*)</span>
              <v-autocomplete
                dense
                hide-details
                outlined
                :items="filteredArticlesByUsedArticles"
                v-model="selectedArtikelnummer"
                item-text="Artikelnummer"
                placeholder="Artikelnummer"
                @change="changedArtikelnummer"
                clearable
              >
                <template v-slot:no-data>
                  <span class="px-2">keine Artikel gefunden</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="font-weight-bold">Artikelname(*)</span>
              <v-autocomplete
                dense
                hide-details
                outlined
                :items="filteredArticlesByUsedArticles"
                v-model="selectedArtikelname"
                item-text="Artikelname"
                placeholder="Artikelname"
                @change="changedArtikelname"
                clearable
              >
                <template v-slot:no-data>
                  <span class="px-2">keine Artikel gefunden</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="font-weight-bold">Artikelzusammenfassung</span>
              <v-select
                dense
                hide-details
                outlined
                :items="filteredArticlesByArticlenumber"
                placeholder="Zusammenfassung"
                item-text="Zusammenfassung"
                item-value="Artikel_ID"
                return-object
                v-model="newDataset"
                :disabled="!selectedArtikelnummer && !selectedArtikelname"
                ><template v-slot:no-data>
                  <span class="px-2">keine Artikel gefunden</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="font-weight-bold">Größe(*)</span>
              <v-select
                dense
                hide-details
                outlined
                :items="filteredArticlesByArticlenumber"
                placeholder="Größe"
                item-text="Größe"
                item-value="Artikel_ID"
                return-object
                v-model="newDataset"
                :disabled="!selectedArtikelnummer && !selectedArtikelname"
                ><template v-slot:no-data>
                  <span class="px-2">keine Artikel gefunden</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense align="center" class="px-0">
            <v-col cols="12">
              <v-alert v-if="filteredArticlesAlreadyRent.length > 0" border="left" colored-border color="red lighten-2" elevation="2" class="subtitle-2 ma-0">
                <v-row dense align="center"
                  ><v-col cols="12" class="py-0 font-weight-bold">
                    Artikel wird bereits verliehen!
                  </v-col></v-row
                >
                <v-row dense align="center" v-for="item in filteredArticlesAlreadyRent" :key="item.Termin_ID">
                  <v-col cols="12" class="py-0">
                    {{
                      "am " +
                        new Date(item.Datum).toLocaleDateString("de-DE", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }) +
                        " an " +
                        item.Name
                    }}
                  </v-col>
                </v-row>
              </v-alert>
              <v-alert v-if="newDataset && newDataset.Status_ID === 3" border="left" colored-border color="red lighten-2" elevation="2" class="subtitle-2 ma-0">
                <v-row dense align="center"
                  ><v-col cols="12" class="font-weight-bold pb-0">
                    Dieser Artikel ist derzeit verkauft ...
                  </v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col v-if="newDataset.Bestellstatus_ID === 2" cols="12" class="pt-0">
                    <v-row dense>
                      <v-col cols="12" class="pt-0">
                        ... und ist bestellbar.
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" class="pt-0">
                        Bei Auswahl des Artikels wird eine Bestellvormerkung erstellt!
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="newDataset.Bestellstatus_ID === 1" cols="12">
                    <v-row dense>
                      <v-col cols="12" class="pt-0">
                        ... und nicht bestellbar!
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" class="pt-0">
                        Der Artikel kann also nicht ausgewählt werden!
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="newDataset.Bestellstatus_ID === 3" cols="12">
                    <v-row dense>
                      <v-col cols="12" class="font-weight-bold pt-0">
                        ... aber bereits zum Bestellen vorgemerkt.
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" class="pt-0">
                        Bei Auswahl des Artikels wird die vorgemerkte Bestellmenge um 1 erhöht!
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12">
              <v-card tile outlined>
                <v-col cols="12" class="pa-1">
                  <v-row dense>
                    <v-col cols="5" class="font-weight-bold">
                      Artikelnummer
                    </v-col>
                    <v-col cols="7">
                      <span v-if="newDataset.Artikelnummer">
                        {{ newDataset.Artikelnummer }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="5" class="font-weight-bold">
                      Artikelname
                    </v-col>
                    <v-col cols="7">
                      <span v-if="newDataset.Name">
                        {{ newDataset.Name }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="5" class="font-weight-bold">
                      Größe
                    </v-col>
                    <v-col cols="7">
                      <span v-if="newDataset.Größe">
                        {{ newDataset.Größe }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="5" class="font-weight-bold">
                      Farbe(n)
                    </v-col>
                    <v-col cols="7">
                      <span v-if="newDataset.Farben">
                        {{ newDataset.Farben }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="5" class="font-weight-bold">
                      Stoff(e)
                    </v-col>
                    <v-col cols="7">
                      <span v-if="newDataset.Stoffe">
                        {{ newDataset.Stoffe }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="5" class="font-weight-bold">
                      Schnitt
                    </v-col>
                    <v-col cols="7">
                      <span v-if="newDataset.Schnitt">
                        {{ newDataset.Schnitt }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="5" class="font-weight-bold">
                      Besonderheiten
                    </v-col>
                    <v-col cols="7">
                      <span v-if="newDataset.Besonderheiten">
                        {{ newDataset.Besonderheiten }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="5" class="font-weight-bold">
                      VK brutto
                    </v-col>
                    <v-col cols="7">
                      <span v-if="newDataset.VK_brutto">
                        {{ newDataset.VK_brutto + " €" }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="5" class="font-weight-bold">
                      Leihpreis
                    </v-col>
                    <v-col cols="7">
                      <span v-if="newDataset.Leihpreis">
                        {{ newDataset.Leihpreis + " €" }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense align="center" class="">
            <v-col cols="12" xl="5" lg="5" md="5" sm="5">
              <span class="font-weight-bold">Preis(*)</span>
              <v-text-field dense hide-details outlined v-model="newDataset.Preis" @change="formatPreis" placeholder="0,00" suffix="€"></v-text-field>
            </v-col>
            <v-col cols="12" xl="5" lg="5" md="5" sm="5">
              <span class="font-weight-bold">Artikel wird...</span>
              <v-select dense :items="Aktionen" hide-details outlined v-model="newDataset.Aktion" placeholder="..." item-text="text" item-value="value"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-divider inset></v-divider>
      <v-card-actions class="px-1">
        <v-row dense align="center">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn light class="green lighten-2 grey--text text--lighten-4" @click="addArticle()" :disabled="validAdd">
              <v-icon left>mdi-check </v-icon>
              <span>Artikel hinzufügen</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    usedArticles: { type: Array, default: Array },
    initialDataset: { type: Object, default: Object },
  },

  data: () => ({
    SelectArticle: false,
    selectedArtikelnummer: "",
    selectedArtikelname: "",
    Artikel: [],
    verlieheneArtikel: [],
    newDataset: {},
    defaultDataset: {},
    Aktionen: [
      {
        value: "Verkauf",
        text: "verkauft",
      },
      {
        value: "Verleih",
        text: "verliehen",
      },
    ],
  }),

  computed: {
    validAdd() {
      if (!this.newDataset.Artikel_ID || !this.newDataset.Preis || !this.newDataset.Aktion || (this.newDataset.Status_ID === 3 && this.newDataset.Bestellstatus_ID === 1)) {
        return true;
      } else return false;
    },

    filteredArticlesAlreadyRent() {
      if (this.newDataset) {
        return this.verlieheneArtikel.filter((it) => it.Artikel_ID === this.newDataset.Artikel_ID);
      } else return [];
    },

    filteredArticlesByUsedArticles() {
      if (this.usedArticles) {
        return this.Artikel.filter((it) => this.usedArticles.indexOf(it.Artikel_ID) === -1);
      } else return this.Artikel;
    },

    filteredArticlesByArticlenumber() {
      if (this.selectedArtikelnummer) {
        return this.filteredArticlesByUsedArticles.filter((item) => item.Artikelnummer === this.selectedArtikelnummer);
      } else if (this.selectedArtikelname) {
        return this.filteredArticlesByUsedArticles.filter((item) => item.Artikelname === this.selectedArtikelname);
      } else return [];
    },
  },

  methods: {
    open() {
      this.initialize();
      this.SelectArticle = true;
    },

    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/dataset/createappointment/selectarticle`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      _.forEach(this.Artikel, (x) => {
        if (x.Artikelgruppe == null && x.Artikeluntergruppe == null) {
          _.set(x, "Zusammenfassung", `${x.Abteilung}, ${x.Farben}, ${x.Stoffe}, ${x.Größe}`);
        } else if (x.Artikelgruppe != null && x.Artikeluntergruppe == null) {
          _.set(x, "Zusammenfassung", `${x.Artikelgruppe}, ${x.Farben}, ${x.Stoffe}, ${x.Größe}`);
        } else if (x.Artikelgruppe != null && x.Artikeluntergruppe != null) {
          _.set(x, "Zusammenfassung", `${x.Artikeluntergruppe}, ${x.Farben}, ${x.Stoffe}, ${x.Größe}`);
        }
      });

      if (this.initialDataset.Artikel_ID) {
        this.newDataset = Object.assign(
          {},
          this.Artikel.find((x) => x.Artikel_ID === this.initialDataset.Artikel_ID)
        );
        this.selectedArtikelnummer = this.initialDataset.Artikelnummer;
      } else {
        this.selectedArtikelnummer = "";
        this.newDataset = Object.assign({}, this.defaultDataset);
      }
    },

    changedArtikelnummer() {
      this.newDataset = {};
      this.selectedArtikelname = "";
    },

    changedArtikelname() {
      this.newDataset = {};
      this.selectedArtikelnummer = "";
    },

    formatPreis() {
      if (this.newDataset.Preis != null) {
        const preis = this.newDataset.Preis;
        const preis_string = String(parseFloat(preis.replace(",", ".")).toFixed(2)).replace(".", ",");
        this.newDataset.Preis = preis_string;
      }
    },

    clearPreis() {
      this.newDataset.Preis = null;
    },

    addArticle() {
      this.SelectArticle = false;
      Object.assign(this.newDataset, { neu: true });
      this.$emit("addArticle", this.newDataset);
      this.newDataset = Object.assign({}, this.defaultDataset);
    },

    close() {
      this.SelectArticle = false;
      this.newDataset = Object.assign({}, this.defaultDataset);
    },

    clearOnClickOutside() {
      this.newDataset = {};
      this.selectedArtikelnummer = "";
      this.selectedArtikelname = "";
    },
  },
};
</script>
