<template>
  <v-dialog
    v-model="Note"
    width="475px"
    overlay-opacity="0.75"
  >
    <v-card tile class="pa-3">
      <v-card-title
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title>Notiz hinzufügen</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>
      <v-divider inset></v-divider>

      <v-card-text class="text--primary py-5 px-3">
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="12"
              >
              <v-textarea clearable autofocus placeholder="Notiz" auto-grow rows="4" v-model="newNote.Notiz">
              
              </v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-divider inset></v-divider>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-spacer></v-spacer>            
            <v-col cols="auto">
              <v-btn  class="green lighten-2 grey--text text--lighten-4" @click="addNote()" :disabled="!newNote.Notiz">
                <v-icon left
                  >mdi-check
                </v-icon>
                <span 
                  >Notiz hinzufügen</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    Note: false,
    newNote: {
        temp_id: null,
        Notiz: "",
    },
    defaultNote: {
        temp_id: null,
        Notiz: "",
    }
  }),

  methods: {
    open(noteid) {
      this.Note = true;
      this.newNote.temp_id = noteid;
    },

    addNote() {
      this.Note = false;
      Object.assign(this.newNote, {neu: true});
      this.$emit("addNote", this.newNote);
      this.newNote = Object.assign({}, this.defaultNote);
    },

    close() {
      this.Note = false;
      this.newNote = Object.assign({}, this.defaultNote);
    },
  },
};
</script>
